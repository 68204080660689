import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import Privacy from './Components/Privacypolicy/Privacy';
import Terms from './Components/Terms&Conditions/Terms';
import Shipping from './Components/Shipping&Delivery/Shipping';
import Refund from './Components/Cancel&Refund/Refund'


function App() {
  return (
    <div>

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Terms&Conditions" element={<Terms />} />
        <Route path="/PrivacyPolicy" element={<Privacy />} />
        <Route path="/Shipping&Delivery" element={<Shipping />} />
        <Route path="/Cancellation&Refund" element={<Refund />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
