import React, {useState} from 'react'
import './Privacy.css'

import logo from '../Assests/kalki.png'
const Privacy = () => {

    const [isOverlayOpen, setOverlayOpen] = useState(false);

    const toggleOverlay = () => {
      setOverlayOpen(!isOverlayOpen);
    };


  return (
    <div>

<div className="nav">
       <header className="navbar">
        <div className="navbar-logo">
            <img src={logo} alt="Sri Kalki Fibernet logo" />
        </div>
        <nav className="navbar-links">
          <a href="/">Home</a>
          <a href="/">About</a>
          <a href="/">Services</a>
          <a href="/">FAQ</a>
          <a href="/">Contact</a>
          <button className="download-brochure">Download Brochure</button>
        </nav>

        <div className="hamburger" onClick={toggleOverlay}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
      </div>

    
<div className={`overlay ${isOverlayOpen ? 'open' : ''}`}>
  <button className="overlay-close" onClick={toggleOverlay}>X</button>
  <nav className="overlay-links">
    <a href="/" onClick={toggleOverlay}>Home</a>
    <a href="/" onClick={toggleOverlay}>About</a>
    <a href="/" onClick={toggleOverlay}>Services</a>
    {/* <a href="/" onClick={toggleOverlay}>Testimonials</a> */}
    <a href="/" onClick={toggleOverlay}>FAQ</a>
    <a href="/" onClick={toggleOverlay}>Contact</a>
  </nav>
</div>

        <div className="privacy">
            <div className="policy1">
            <h3>Privacy Policy</h3>
            <h5>At Sri Kalkifibernet , we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you access our internet and cable TV services. By using our services, you agree to the practices described in this policy.</h5>

            <h4>1. Information Collection: We may collect personal information, including but not limited to your name, address, contact details (phone number, email), and payment information, when you subscribe to our internet and cable TV services. Additionally, we may gather non-personal information such as IP addresses, browsing activities, and device information to improve our services and analyze user trends.</h4>

            <h4>2. Use of Information: We use the collected information for the following purposes: a. Providing and maintaining our internet and cable TV services. b. Responding to your inquiries, requests, and customer support needs. c. Processing payments and billing for our services. d. Sending you important service-related communications, such as maintenance updates and service announcements. e. Improving our services, understanding customer preferences, and enhancing user experience. f. Complying with legal obligations and resolving disputes.</h4>

            <h4>3. Information Sharing: We do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your information with trusted third-party service providers who assist us in delivering our services (e.g., payment processors, technical support). We ensure that these third parties maintain strict confidentiality and adhere to data protection regulations. </h4>

            <h4>4. Data Security: We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. Our website and databases are secured with industry-standard encryption protocols and regularly updated security software.</h4>

            <h4>5. Retention Period: We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</h4>

            <h4>6. Your Rights: You have the right to access, correct, update, and delete your personal information held by us. If you wish to exercise any of these rights or have questions about our privacy practices, please contact us using the provided contact details.</h4> 

            <h4>7. Amendments: We reserve the right to update and modify this Privacy Policy from time to time. Any changes will be posted on our website, and the updated policy will apply to information collected after the date of the revision.</h4>
        </div>

        </div>

   
        <section id="contact" className="contact" >  

<footer className="footer-section">
  <div className="footer-content">
    <div className="footer-column">
      <h3>Sri Kalkifibernet</h3>
      <p>
        We are also providing broadband service in Pondicherry. We are the only company in Pondicherry, providing multiple broadband internet services,Cable TV, Apple TV and OTTs under one roof to delight our customer requirements.
      </p>
      <p className="footer-phone">
        <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
      </p>
      <div className="policies">
      <a href="/Terms&Conditions">Terms And Conditions</a>
      <a href="/PrivacyPolicy">Privacy Policy</a>
      <a href="/Cancellation&Refund">Cancellation & Refund</a>
      <a href="/Shipping&Delivery">Shipping & Delivery</a>
      
      </div>
    </div>
    <div className="footer-column"> 
      <h4>Contact Info</h4>
      <hr className="underline" />
      <p>No, 216 Sanmargan street, North bharathipuram, Shanmugapuram, Pondicherry - 605009</p>
      <p>📧 kalkisat97@gmail.com</p>
      <p>📞 +91- 8489792830, 9894811411</p>
      
    </div>
    <div className="footer-column">
      <h4>Quick Services</h4>
      <hr className="underline" />
      <p>We are open to provide internet solutions, OTT (Over-The-Top) streaming services, cable TV, and Apple TV packages service support </p>
    </div>
  </div>
  <div className="footer-bottom">
    <p>Copyright © 2024 Sri Kalkifibernet | Powered by Sri Kalkifibernet</p>
  </div>
</footer>
         
        </section>


    </div>
  )
}

export default Privacy