import React, {useState} from 'react'
import './Refund.css'

import logo from '../Assests/kalki.png'

const Refund = () => {

    
    const [isOverlayOpen, setOverlayOpen] = useState(false);

    const toggleOverlay = () => {
      setOverlayOpen(!isOverlayOpen);
    };


  return (
    <div>
      
        
<div className="nav">
       <header className="navbar">
        <div className="navbar-logo">
            <img src={logo} alt="Sri Kalki Fibernet logo" />
        </div>
        <nav className="navbar-links">
          <a href="/">Home</a>
          <a href="/">About</a>
          <a href="/">Services</a>
          <a href="/">FAQ</a>
          <a href="/">Contact</a>
          <button className="download-brochure">Download Brochure</button>
        </nav>

        <div className="hamburger" onClick={toggleOverlay}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
      </div>

    
<div className={`overlay ${isOverlayOpen ? 'open' : ''}`}>
  <button className="overlay-close" onClick={toggleOverlay}>X</button>
  <nav className="overlay-links">
    <a href="/" onClick={toggleOverlay}>Home</a>
    <a href="/" onClick={toggleOverlay}>About</a>
    <a href="/" onClick={toggleOverlay}>Services</a>
    {/* <a href="/" onClick={toggleOverlay}>Testimonials</a> */}
    <a href="/" onClick={toggleOverlay}>FAQ</a>
    <a href="/" onClick={toggleOverlay}>Contact</a>
  </nav>
</div>


<div className="cancel">
    <div className="cancellation">
        <h2>Cancellation Policy</h2>
        <h3>Service Cancellations</h3>
        <h4>1. Requesting a Cancellation:</h4>
        <h5>A. Customers may request the cancellation of their subscription or service at any time by contacting our customer support team. <br />
            B. Cancellations will take effect at the end of the current billing cycle. This means you will still have access to the service until the billing cycle is complete. <br />
            C. If you do not wish to continue using our service, we recommend initiating the cancellation process at least 24 hours before the next billing date to avoid any accidental charges.</h5>

        <h4>2.Service-Specific Cancellation Rules:</h4>
        <h5>A. Some of our services may have unique cancellation terms. For example, services with minimum contract periods may incur early termination fees if canceled before the agreed period. <br />
            B. Any service-specific cancellation terms will be outlined in the service agreement provided at the time of purchase. <br />
            C. Certain services that have been customized to meet individual needs may not be eligible for cancellation once they have started.</h5>

        <h4>3. Reactivation:</h4>
        <h5>A. If you decide to reactivate your account after cancellation, you may be subject to a reactivation fee. Any previously applied discounts or promotions may not be available upon reactivation. <br />
            B. Reactivations are subject to our terms and availability at the time of the request. <br /></h5>

        <h3>Promotional Packages</h3>
        <h4>1. Cancellation of Promotional Subscriptions:</h4>
        <h5>A. Promotional packages and discounted subscriptions are often offered for a limited period. Once enrolled in a promotional package, it may not be eligible for early cancellation or refunds unless otherwise specified. <br />
            B. If a promotional package is canceled during the promotional period, you may forfeit any remaining days or discounts associated with the promotion. <br />
            C. If you cancel and then wish to re-enroll in a promotional package, you may not receive the same discounted rate, as promotions are typically available only to first-time subscribers or during specified periods.</h5>

        <h4>2. Non-Refundable Promotions:</h4>
        <h5>A. Some promotional offers are non-refundable, even if you cancel the service before the promotion period ends. These include one-time discounts, holiday specials, or other limited-time deals that explicitly state non-refundability at the time of purchase. <br />
            B. Please review the terms of any promotional package carefully, as cancellations for these types of promotions may not result in a refund or credit to your account. </h5>
            

        <h4>3. Trial Periods and Introductory Offers:</h4>
        <h5>A. If your promotional package includes a free or discounted trial period, you must cancel before the trial period ends to avoid being charged the full subscription rate. <br />
            B.After the trial period, the regular subscription rate will automatically apply. Early cancellation during a free trial will immediately end your access to the service.</h5>

        <h4>4. Switching Between Promotions:</h4>
        <h5>A. Customers currently enrolled in a promotional package may not be eligible to switch to a different promotional offer. Promotions are generally limited to one per customer. <br />
            B. If you are interested in changing or upgrading your service during a promotional period, please contact customer support to discuss available options, as standard rates may apply.</h5>

        
    </div>
    <div className="refund">
    <h3>Refund Policy</h3>
            <h5>At Sri Kalkifibernet, we strive to provide reliable and high-quality internet and cable TV services. However, we understand that circumstances may arise where you may need to request a refund. This Refund Policy outlines the conditions under which refunds may be issued for our services.</h5>

            <h4>1. Eligibility for Refund: a. Internet Services: If you experience a service outage lasting more than 24 hours due to a fault on our end and we are unable to resolve the issue promptly, you may be eligible for a pro-rata refund for the affected period. b. Cable TV Services: In the event of prolonged service disruption, we will assess the situation and provide a pro-rata refund if the fault lies with our infrastructure.</h4>

            <h4>2. Non-Eligibility for Refund: a. Refunds will not be provided for any partial or full outage caused by factors beyond our control, including but not limited to natural disasters, power failures, or network outages beyond our service area. b. Refunds will not be granted for issues resulting from the customer’s failure to comply with our terms of service or any actions that compromise the stability and security of our network.</h4>

            <h4>3. Refund Process: To request a refund, please contact our customer support team using the provided contact details. You will need to provide relevant information about the service disruption and any supporting evidence. We will evaluate your request and, if eligible, process the refund within a reasonable timeframe. </h4>

            <h4>4. Payment Method: Refunds, when approved, will be issued using the same payment method used for the original purchase.</h4>

            <h4>5. Amendments: We reserve the right to update and modify this Refund Policy from time to time. Any changes will be posted on our website, and the updated policy will apply to refund requests made after the date of the revision.</h4>

            <h5>For any further clarification or assistance regarding our Privacy Policy or Refund Policy, please don’t hesitate to contact us using the provided contact information. We value your trust and are committed to ensuring a positive experience with Sri Kalkifibernet : kalkisat97@gmail.com.</h5>
    </div>
</div>





<section id="contact" className="contact" >  

<footer className="footer-section">
  <div className="footer-content">
    <div className="footer-column">
      <h3>Sri Kalkifibernet</h3>
      <p>
        We are also providing broadband service in Pondicherry. We are the only company in Pondicherry, providing multiple broadband internet services,Cable TV, Apple TV and OTTs under one roof to delight our customer requirements.
      </p>
      <p className="footer-phone">
        <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
      </p>
      <div className="policies">
      <a href="/Terms&Conditions">Terms And Conditions</a>
      <a href="/PrivacyPolicy">Privacy Policy</a>
      <a href="/Cancellation&Refund">Cancellation & Refund</a>
      <a href="/Shipping&Delivery">Shipping & Delivery</a>
      
      </div>
    </div>
    <div className="footer-column"> 
      <h4>Contact Info</h4>
      <hr className="underline" />
      <p>No, 216 Sanmargan street, North bharathipuram, Shanmugapuram, Pondicherry - 605009</p>
      <p>📧 kalkisat97@gmail.com</p>
      <p>📞 +91- 8489792830, 9894811411</p>
      
    </div>
    <div className="footer-column">
      <h4>Quick Services</h4>
      <hr className="underline" />
      <p>We are open to provide internet solutions, OTT (Over-The-Top) streaming services, cable TV, and Apple TV packages service support </p>
    </div>
  </div>
  <div className="footer-bottom">
    <p>Copyright © 2024 Sri Kalkifibernet | Powered by Sri Kalkifibernet</p>
  </div>
</footer>
         
        </section>


    </div>
  )
}

export default Refund
