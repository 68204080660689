import React,{useState, useRef} from 'react'
import './Home.css'
import emailjs from "emailjs-com";


import logo from '../Assests/kalki.png'
import about1 from '../Assests/about1.png'
import review1 from '../Assests/google.png'
import review2 from '../Assests/facebook.png'
import customer from '../Assests/customer.png'
import apple from '../Assests/apple.png'
import cable from '../Assests/cable.png'
import ott from '../Assests/ott.png'
import arrow from '../Assests/arrow.png'
import internet from '../Assests/internet.png'


const Home = () => {

    const [isOverlayOpen, setOverlayOpen] = useState(false);

    const toggleOverlay = () => {
      setOverlayOpen(!isOverlayOpen);
    };

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_dx7y0iv", // Replace with your EmailJS service ID
          "template_mkqfzjn", // Replace with your EmailJS template ID
          form.current,
          "SYyDXLvMlYo_jZxBO" // Replace with your EmailJS public key
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Message sent successfully!");
          },
          (error) => {
            console.log(error.text);
            alert("Failed to send the message. Please try again.");
          }
        );
  
      e.target.reset(); // Reset form fields
    };

  return (
    <div>
        <div className="nav">
       <header className="navbar">
        <div className="navbar-logo">
            <img src={logo} alt="Sri Kalki Fibernet logo" />
        </div>
        <nav className="navbar-links">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          {/* <a href="#testimonials">Testimonials</a> */}
          <a href="#faq">FAQ</a>
          <a href="#contact">Contact</a>
          <button className="download-brochure">Download Brochure</button>
        </nav>

        <div className="hamburger" onClick={toggleOverlay}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
      </div>

    
<div className={`overlay ${isOverlayOpen ? 'open' : ''}`}>
  <button className="overlay-close" onClick={toggleOverlay}>X</button>
  <nav className="overlay-links">
    <a href="#home" onClick={toggleOverlay}>Home</a>
    <a href="#about" onClick={toggleOverlay}>About</a>
    <a href="#services" onClick={toggleOverlay}>Services</a>
    {/* <a href="#testimonials" onClick={toggleOverlay}>Testimonials</a> */}
    <a href="#faq" onClick={toggleOverlay}>FAQ</a>
    <a href="#contact" onClick={toggleOverlay}>Contact</a>
  </nav>
</div>

        <section id="home" className="home" >
        <div className="quote-section">
        <div className="quote-content">
          <div className="quote-text">
            <p className="quote-subtitle">Best Broadband Solution in Pondicherry</p>
            <p className="quote-description">
             we are a dedicated fiber broadband service provider that aims to deliver high-speed internet connectivity and digital entertainment solutions to homes and businesses. Known for its reliable and affordable services, Sri Kalki Fibernet specializes in providing comprehensive internet solutions, OTT (Over-The-Top) streaming services, cable TV, and Apple TV packages.
            </p>
            <div className="quote-support">
              <p>Customer support Call - 12/7</p>
              <p className="quote-phone">
                <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
              </p>
            </div>
          </div>
          <div className="quote-form">
      <h2>Request A Free Quote</h2>
      <form ref={form} onSubmit={sendEmail}>
        <label>
          Name
          <input type="text" name="name" required />
        </label>
        <label>
          Email
          <input type="email" name="email" required />
        </label>
        <label>
          Subject
          <input type="text" name="subject" required />
        </label>
        <label>
          How may we help you?
          <textarea name="message" required></textarea>
        </label>
        <button type="submit" className="quote-button">Get A Quote</button>
      </form>
    </div>
        </div>
      </div>  
        </section>
        
        <section id="about" className="about" >
         <div className="about-section">
         <div className="about-content">
         <div className="about-images">
          <img src={about1} alt="Description 1" className="image-top" />
          {/* <img src={about2} alt="Description 2" className="image-bottom" /> */}
          </div>
         <div className="about-text">
           <h2>About Us</h2>
           <h3>We are end to end network providers</h3>
           <p>
            Sri Kalki Fibernet is a leading provider of fiber broadband and digital entertainment solutions, committed to delivering high-speed connectivity and a wide array of digital services to households and businesses. Recognized for its reliable and efficient service, Sri Kalki Fibernet brings the power of advanced fiber technology to ensure seamless internet experiences tailored to modern demands. Their offerings include high-speed internet with competitive plans, OTT (Over-The-Top) streaming services, cable TV, and Apple TV support, catering to a variety of digital needs.
             With Sri Kalki Fibernet, customers gain access to popular OTT platforms, allowing them to enjoy unlimited entertainment, from movies to series, right at their fingertips. Their cable TV services bring a diverse selection of channels, including news, sports, entertainment, and educational content, offering something for everyone in the family. For Apple users, Sri Kalki Fibernet provides comprehensive support for Apple TV, enabling a premium streaming experience.
            </p>
              <a href=""><button className='READ'>READ MORE <span><img src={arrow} alt="Right Arrow" /> <img src={arrow} alt="Right Arrow" /></span> </button></a>  
          </div>
         
        </div>
        </div>
          
        </section>
        
        <section id="services" className="service" >
        <div className="services">
        <div className="services-header">
    <h2>Services</h2>
    <p>Available for 12/7 Service</p>
    <hr className="underline" />
  </div>
  <div className="services-grid">
    <div className="service-item">
      <h3> <span className='num'> 01.</span><br />Internet Provider</h3>
      <img src={internet} alt="Internet Provider" />
      <p>Offering robust, fast, and stable internet connections tailored to meet the demands of both residential and commercial customers.</p>
      <a href="#contact">Contact us for plans</a>
    </div>
    <div className="service-item">
      <h3><span className='num'> 02.</span><br />Cable TV</h3>
      <img src={cable} alt="Cable TV" />
      <p>A traditional yet valuable service, their cable TV packages offer a range of channels, covering everything from news to entertainment.</p>
      <a href="#contact">Contact us</a>
    </div>
    <div className="service-item">
      <h3><span className='num'> 03.</span><br />Apple TV</h3>
      <img src={apple} alt="Apple TV" />
      <p>For customers who prefer Apple’s ecosystem, Sri Kalki Fibernet also provides support for Apple TV, bringing premium content and streaming capabilities to Apple users.</p>
      <a href="#contact">Contact us</a>
    </div>
    <div className="service-item">
      <h3><span className='num'> 04.</span><br />OTTs</h3>
      <img src={ott} alt="OTTs" />
      <p>Providing access to popular streaming platforms, Sri Kalki Fibernet ensures customers can enjoy a wide array of digital content, including movies, TV shows, and exclusive online series.</p>
      <a href="#contact">Contact us for plans</a>
    </div>
  </div>
  </div>  
        </section>
        
        {/* <section id="testimonials" className="testimonals" >
            <div className="testimonal-section">
        <div className="testimonials-header">
    <h2>Testimonials</h2>
    <p>We provide quality services to customers, we are very much delighted to serve our happy customers.</p>
    <a href="#view-all" className="view-all">View All &raquo;</a>
  </div>
  <div className="testimonials-grid">
    <div className="testimonial-item">
      <p className="testimonial-quote">“</p>
      <p className="testimonial-text">They gave broadband services to our remote location, when everyone said not possible, best solution provider for us! Thank you team.</p>
      <p className="testimonial-author">RAJASEKAR</p>
      <p className="testimonial-location">Dindugal</p>
    </div>
    <div className="testimonial-item">
      <p className="testimonial-quote">“</p>
      <p className="testimonial-text">Fastest broadband with quickest service support in Dindugal.</p>
      <p className="testimonial-author">KUMAR</p>
      <p className="testimonial-location">Dindugal</p>
    </div>
    <div className="testimonial-item">
      <p className="testimonial-quote">“</p>
      <p className="testimonial-text">JR communications has done CCTV installations throughout Dindugal carried out by Dindugal corporations, great support.</p>
      <p className="testimonial-author">DINDUGAL CORPORATION</p>
      <p className="testimonial-location">Dindugal</p>
    </div>
    
  </div>
  </div>
         
        </section> */}
        
        <section id="faq" className="faq" >
    
     <div className="quick-service-section">           
        <div className="reviews-header">
    <p>See Our Reviews On</p>
    <div className="reviews-logos">
      <img src={review1} alt="Google Reviews" className="review-logo" />
      <img src={review2} alt="Facebook Reviews" className="review-logo" />
    </div>
  </div>
  <div className="quick-service-content">
    <div className="service-image">
      <img src={customer} alt="Person" />
    </div>
    <div className="service-details">
      <h2>Quick Service On Call - 12/7</h2>
      <hr className="underline" />
      <p className="contact-number">
        <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
      </p>
    </div>
  </div>
  </div> 
          
        </section>
        
        <section id="contact" className="contact" >  

<footer className="footer-section">
  <div className="footer-content">
    <div className="footer-column">
      <h3>Sri Kalkifibernet</h3>
      <p>
        We are also providing broadband service in Pondicherry. We are the only company in Pondicherry, providing multiple broadband internet services,Cable TV, Apple TV and OTTs under one roof to delight our customer requirements.
      </p>
      <p className="footer-phone">
        <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
      </p>
      <div className="policies">
      <a href="/Terms&Conditions">Terms And Conditions</a>
      <a href="/PrivacyPolicy">Privacy Policy</a>
      <a href="/Cancellation&Refund">Cancellation & Refund</a>
      <a href="/Shipping&Delivery">Shipping & Delivery</a>
      
      </div>
    </div>
    <div className="footer-column"> 
      <h4>Contact Info</h4>
      <hr className="underline" />
      <p>No, 216 Sanmargan street, North bharathipuram, Shanmugapuram, Pondicherry - 605009</p>
      <p>📧 kalkisat97@gmail.com</p>
      <p>📞 +91- 8489792830, 9894811411</p>
      
    </div>
    <div className="footer-column">
      <h4>Quick Services</h4>
      <hr className="underline" />
      <p>We are open to provide internet solutions, OTT (Over-The-Top) streaming services, cable TV, and Apple TV packages service support </p>
    </div>
  </div>
  <div className="footer-bottom">
    <p>Copyright © 2024 Sri Kalkifibernet | Powered by Sri Kalkifibernet</p>
  </div>
</footer>
         
        </section>


      </div>




    
    
  )
}

export default Home