import React,{useState} from 'react'
import './Shipping.css'

import logo from '../Assests/kalki.png'
const Shipping = () => {

    
    const [isOverlayOpen, setOverlayOpen] = useState(false);

    const toggleOverlay = () => {
      setOverlayOpen(!isOverlayOpen);
    };


  return (
    <div>

        
<div className="nav">
       <header className="navbar">
        <div className="navbar-logo">
            <img src={logo} alt="Sri Kalki Fibernet logo" />
        </div>
        <nav className="navbar-links">
          <a href="/">Home</a>
          <a href="/">About</a>
          <a href="/">Services</a>
          <a href="/">FAQ</a>
          <a href="/">Contact</a>
          <button className="download-brochure">Download Brochure</button>
        </nav>

        <div className="hamburger" onClick={toggleOverlay}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
      </div>

    
<div className={`overlay ${isOverlayOpen ? 'open' : ''}`}>
  <button className="overlay-close" onClick={toggleOverlay}>X</button>
  <nav className="overlay-links">
    <a href="/" onClick={toggleOverlay}>Home</a>
    <a href="/" onClick={toggleOverlay}>About</a>
    <a href="/" onClick={toggleOverlay}>Services</a>
    {/* <a href="/" onClick={toggleOverlay}>Testimonials</a> */}
    <a href="/" onClick={toggleOverlay}>FAQ</a>
    <a href="/" onClick={toggleOverlay}>Contact</a>
  </nav>
</div>


<div className="ship">
    <div className="shipping">
    <h2>Shipping and Delivery Policy</h2>
        <h3>1. Shipping Methods and Costs</h3>
        <h4>1. Domestic Shipping:</h4>
        <h5>A. We offer standard and express shipping options for all domestic orders. Shipping rates vary based on the delivery location, package weight, and shipping method chosen at checkout. <br />
            B. Orders over a certain amount may qualify for free standard shipping, as indicated during the checkout process.</h5>

        <h4>2. International Shipping:</h4>
        <h5>A. We currently ship to select countries outside of [Your Country]. International shipping rates vary based on destination, package weight, and preferred shipping method. <br />
            B. Customs duties, taxes, and additional fees for international shipments are the responsibility of the customer. These charges vary by country and will not be covered by us.</h5>

        <h4>3. Special Handling Fees:</h4>
        <h5>A. Certain items may require special handling or additional fees. If applicable, these fees will be clearly listed during the checkout process.</h5>

        <h3>2.  Processing Time</h3>
        <h4>1. Order Processing:</h4>
        <h5>A. Orders are processed within 1-3 business days (excluding weekends and holidays) after receiving the order confirmation email. <br />
            B. During peak periods, such as holidays or sales events, processing times may be extended. Customers will be notified of any expected delays.</h5>

        <h4>2. Verification of Orders:</h4>
        <h5>A. For security reasons, some orders may be subject to additional verification before they are processed and shipped. If your order requires verification, our customer service team will reach out to you.</h5>

        <h3>3. Delivery Time Estimates</h3>
        <h4>1. Standard Shipping:</h4>
        <h5>A. Standard shipping typically takes 5-7 business days for domestic orders and 7-14 business days for international orders. <br />
            B.Delivery times are estimates and may vary based on factors such as destination, carrier delays, and customs clearance (for international orders).</h5>

        <h4>2. Express Shipping:</h4>
        <h5>A. Express shipping options are available for faster delivery. Estimated delivery for express shipping is 2-3 business days for domestic orders and 4-7 business days for international orders. <br />
            B. Additional fees may apply for express shipping services, and delivery timelines may vary based on the destination.</h5>

        <h4>3. Tracking Your Order:</h4>
        <h5>A. Once your order has been shipped, you will receive a tracking number via email. You can use this number to track your order’s status on the carrier’s website. <br />
            B. Please allow 24-48 hours for the tracking information to update after receiving the tracking number.</h5>

        <h3>4. Shipping Restrictions</h3>
        <h4>1. P.O. Boxes and APO/FPO Addresses:</h4>
        <h5>A. At this time, we are unable to ship to P.O. Boxes or APO/FPO military addresses. Please provide a valid street address for delivery.</h5>

        <h4>2. Remote or Restricted Areas:</h4>
        <h5>A. Certain remote or restricted locations may not be serviceable by our carriers or may require extended delivery times. If you reside in a remote area, please contact customer support to confirm availability.
            </h5>

        <h4>3. Prohibited and Restricted Items:</h4>
        <h5>A. Due to legal restrictions, certain items may not be eligible for shipping to specific regions. If your order contains a restricted item, you will be notified, and we will work with you to modify or cancel your order. </h5>

        <h3>5. Failed Delivery Attempts </h3>
        <h4>1. Missed Deliveries:</h4>
        <h5>A. If the carrier is unable to deliver your order due to an incorrect address, absence, or other issue, a delivery attempt notice may be left at your address, and the carrier will attempt re-delivery. <br />
            B. If your package is returned to us due to multiple failed delivery attempts, we will contact you to arrange reshipment. Additional shipping charges may apply.</h5>

        <h4>2. Undeliverable Packages:</h4>
        <h5>A. If a package is deemed undeliverable, it will be returned to our facility. We will reach out to you to confirm your address and discuss re-shipping options. Shipping fees for undeliverable packages are non-refundable.</h5>
       

        <h3>6. Lost or Damaged Items  </h3>
        <h4>1. Lost Packages: </h4>
        <h5>A. If your package is lost in transit, please contact our customer support team. We will work with the carrier to locate your package and keep you updated on the status.<br />
            B. If the package cannot be located, we may issue a replacement or refund, subject to our investigation and carrier verification.</h5>

        <h4>2. Damaged Packages:</h4>
        <h5>A. If you receive an item that has been damaged during transit, please contact us within 7 days of delivery. Provide photos of the damaged item and packaging so we can file a claim with the carrier and assist with a replacement or refund.</h5>
       

    </div>
</div>




<section id="contact" className="contact" >  

<footer className="footer-section">
  <div className="footer-content">
    <div className="footer-column">
      <h3>Sri Kalkifibernet</h3>
      <p>
        We are also providing broadband service in Pondicherry. We are the only company in Pondicherry, providing multiple broadband internet services,Cable TV, Apple TV and OTTs under one roof to delight our customer requirements.
      </p>
      <p className="footer-phone">
        <span role="img" aria-label="phone">📞</span> +91- 8489792830, 9894811411
      </p>
      <div className="policies">
      <a href="/Terms&Conditions">Terms And Conditions</a>
      <a href="/PrivacyPolicy">Privacy Policy</a>
      <a href="/Cancellation&Refund">Cancellation & Refund</a>
      <a href="/Shipping&Delivery">Shipping & Delivery</a>
      
      </div>
    </div>
    <div className="footer-column"> 
      <h4>Contact Info</h4>
      <hr className="underline" />
      <p>No, 216 Sanmargan street, North bharathipuram, Shanmugapuram, Pondicherry - 605009</p>
      <p>📧 kalkisat97@gmail.com</p>
      <p>📞 +91- 8489792830, 9894811411</p>
      
    </div>
    <div className="footer-column">
      <h4>Quick Services</h4>
      <hr className="underline" />
      <p>We are open to provide internet solutions, OTT (Over-The-Top) streaming services, cable TV, and Apple TV packages service support </p>
    </div>
  </div>
  <div className="footer-bottom">
    <p>Copyright © 2024 Sri Kalkifibernet | Powered by Sri Kalkifibernet</p>
  </div>
</footer>
         
        </section>


    </div>
  )
}

export default Shipping